/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("./src/css/materialize.min.css")
require("./src/css/material_icons.css")
require("./src/css/google-fonts.css")
require("./src/css/global.css")
